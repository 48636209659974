import React from 'react';
import { Card, CardText, CardGroup, Link } from 'lib/components';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Demo from '../components/Demo';
import { Link as rLink } from '@reach/router';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, StaticQueryDocument } from 'gatsby';
import Layout from '../components/layout';

type DetailsProp = {
    id: string;
    slug: string;
    name: string;
    description: string;
    cardImage: string;
    sortOrder: number;
};

type LibraryItems = {
    items: DetailsProp[];
};

type LibrariesProps = {
    queryPolicyLibrariesByStatusGSI: LibraryItems;
};

type DataProps = {
    data: { policylibrary: LibrariesProps };
};

const query: StaticQueryDocument = graphql`
    query HomeQuery {
        policylibrary {
            queryPolicyLibrariesByStatusGSI(status: "active") {
                items {
                    id
                    name
                    slug
                    description
                    sortOrder
                    cardImage
                }
            }
        }
        locales: allLocale(filter: { ns: { in: ["general", "policy-library"] } }) {
            edges {
                node {
                    language
                    data
                    ns
                }
            }
        }
    }
`;

/**
 * Default page for application
 *
 * @param {data} DataProps Data return from the graphql query
 *
 * @return {React.ReactElement}
 */
function Home({ data }: DataProps): React.ReactElement {
    // Make sure the order is correct as setup in Internal Library
    data.policylibrary.queryPolicyLibrariesByStatusGSI.items.sort(function (a, b) {
        if (a.sortOrder < b.sortOrder) {
            return -1;
        }
        if (a.sortOrder > b.sortOrder) {
            return 1;
        }
        return 0;
    });

    const { t } = useTranslation();

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Layout>
            <div>
                <CardGroup name='libraries' xs={12} sm={6} md={4} wrapChildren={true}>
                    {data.policylibrary.queryPolicyLibrariesByStatusGSI.items.map((library) => {
                        return (
                            <a key={library.id} href={`/library/${library.slug}`}>
                                <div className='card card-flip img-fluid h-100'>
                                    <div className='card-front'>
                                        <img
                                            className='card-img-top'
                                            src={`https://cards.prod.policylibrary.mcn-data.net/${library.cardImage}`}
                                            alt={library.name}
                                        />
                                        <div className='card-img-overlay text-white d-flex text-center justify-content-center align-items-center overlay-mcn-blue'>
                                            <h4>{library.name}</h4>
                                        </div>
                                    </div>
                                    <div className='card-back'>
                                        <div
                                            className='px-2 d-flex text-center justify-content-center align-items-center'
                                            dangerouslySetInnerHTML={{ __html: library.description }}
                                        />
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </CardGroup>
            </div>
        </Layout>
    );
}

export { Home as default, query };
